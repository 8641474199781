import React from "react";
import Header from "../components/common/header";
import "../styles/home.css";
import Hero from "../components/home/hero/Hero.tsx";
import OurServices from "../components/home/ourServices";
import Cars from "../components/home/cars";
import Footer from "../components/common/footer";
import WhyChoose from "../components/home/whychoose";
import Whatsapp from "../components/common/Whatsapp";

const Home = () => {
  return (
    <div>
      <>
        <Whatsapp />

            <div class="hero-header-container">
              <Header />
              <Hero />
            </div>
   
        <Cars />
        <WhyChoose />
        <OurServices />
        <Footer />
      </>
    </div>
  );
};

export default Home;
