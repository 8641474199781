import React, { useRef, useState } from "react";
import "./hero.css";
import rushImg from "../../../assets/home/hero/rush_hero.png";
import expanderHero from "../../../assets/home/hero/expander_hero.png";
import { motion, AnimatePresence } from "framer-motion";
import { SlArrowLeft } from "react-icons/sl";
import { SlArrowRight } from "react-icons/sl";
import BookingFormCat from "../BookingFormCat";

interface HeroScroller {
  titleLineOne: string;
  titleLineTwo: string;
  keyPoint: string;
  imageUrl: string;
  carName: string;
}

const scrollerData: HeroScroller[] = [
  {
    titleLineOne: "step into",
    titleLineTwo: "where luxury meets adventure.",
    keyPoint: "mitsubhishi expander.",
    imageUrl: "expande",
    carName: "Mitsubishi Expander"
  },
  {
    titleLineOne: "experience the",
    titleLineTwo: "luxury redefined for your adventures.",
    keyPoint: "toyota rush.",
    imageUrl: "rush",
    carName: "Toyota Rush"
  },
 
];

const Hero: React.FC = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [car, setCar] = useState("Default car");
  const scrollRef = useRef<HTMLDivElement>(null);

  const handleNext = () => {
    setCurrentSlide((prev) => (prev + 1) % scrollerData.length);
    if (scrollRef.current) {
      scrollRef.current.scrollBy({
        left: scrollRef.current.clientWidth,
        behavior: "smooth",
      });
    }
  };

  const handlePrev = () => {
    setCurrentSlide((prev) => (prev - 1) % scrollerData.length);
    if (scrollRef.current) {
      scrollRef.current.scrollBy({
        left: -scrollRef.current.clientWidth,
        behavior: "smooth",
      });
    }
  };

  const variants = {
    hidden: { opacity: 0, y: 50, filter: "blur(10px)" },
    visible: { opacity: 1, y: 0, filter: "blur(0px)" },
    exit: { opacity: 0, y: -50, filter: "blur(10px)" },
  };

  return (
    <>
      <div className="scroller-main-container">
        <button onClick={handlePrev} disabled={currentSlide === 0}>
          <SlArrowLeft size={40} />
        </button>
        <div className="scroller-content-container" ref={scrollRef}>
          <AnimatePresence>
            {scrollerData.map(
              (item, index) =>
                index === currentSlide && (
                  <div className="scroller-container" key={index}>
                    <motion.div
                      initial="hidden"
                      animate="visible"
                      transition={{ duration: 0.5 }}
                      variants={variants}
                    >
                      <h4>{item.titleLineTwo}</h4>
                    </motion.div>
                    <motion.div
                      initial="hidden"
                      animate="visible"
                      transition={{ duration: 0.5, delay: 0.5 }}
                      variants={variants}
                    >
                      <h3>
                        {item.titleLineOne} <span>{item.keyPoint}</span>
                      </h3>
                    </motion.div>
                    <div className="sc-button-container">
                      <motion.div
                        initial="hidden"
                        animate="visible"
                        transition={{ duration: 0.5, delay: 0.7 }}
                        variants={variants}
                      >
                        <button
                          className="sc-btn-1"
                          onClick={() => {
                            setIsOpen(true);
                            setCar(item.carName);
                          }}
                        >
                          book now
                        </button>
                      </motion.div>
                      {/* <motion.div
                        initial="hidden"
                        animate="visible"
                        transition={{ duration: 0.5, delay: 0.9 }}
                        variants={variants}
                      >
                        <button className="sc-btn-2">drive now</button>
                      </motion.div> */}
                    </div>
                    <motion.div
                      initial="hidden"
                      animate="visible"
                      transition={{ duration: 0.5, delay: 1.2 }}
                      variants={variants}
                      className="sc-image-container"
                    >
                      <img
                        src={item.imageUrl === "rush" ? rushImg : expanderHero}
                        alt="hero image rush and expander"
                        className="hr-car-image"
                      />
                    </motion.div>
                  </div>
                )
            )}
          </AnimatePresence>
        </div>
        <button onClick={handleNext}>
          {" "}
          <SlArrowRight size={40} />
        </button>
        <BookingFormCat
          open={isOpen}
          close={() => setIsOpen(false)}
          car={car}
        />
      </div>
    </>
  );
};

export default Hero;
