import React, { useState } from "react";
import { Link } from "react-router-dom";
import { CiMenuFries } from "react-icons/ci";
import { IoClose } from "react-icons/io5";
import "./headerSub.css";

const HeaderSub = () => {
  const [showMenu, setShowMenu] = useState<Boolean>(false);
  return (
    <>
      <div className="navigation-sub-container">
        <nav className="navigation-sub">
          <div className="nav-container-sub">
            {/* Brand Logo */}
            <a href="/" className="nav-brand-sub">
              YULU&nbsp;DUBAI
            </a>
            {/* Navigation Links */}
            <ul className="nav-links-sub">
              <li key={"home"}>
                <Link to="/">HOME</Link>
              </li>
              <li key={"service"}>
                <Link to="/">SERVICES</Link>
              </li>
              <li key={"about"}>
                <Link to="/about">ABOUT&nbsp;US</Link>
              </li>
              <li key={"contact"}>
                <Link to="/contact">CONTACT&nbsp;US</Link>
              </li>
              {/* Add more link elements as needed */}
            </ul>
            <div className="hamberger__menu__icon">
              <button onClick={() => setShowMenu(true)}>
                <CiMenuFries />
              </button>
            </div>
          </div>
          <div
            className={`${
              showMenu === true ? "hamberger__menu" : "hamberger__menu__hide"
            }`}
          >
            <ul>
              <li>
                <Link
                  to="/"
                  style={{ color: "#707070", textDecoration: "none" }}
                >
                  HOME
                </Link>
              </li>
              <li>
                <Link
                  to="/"
                  style={{ color: "#707070", textDecoration: "none" }}
                >
                  SERVICES
                </Link>
              </li>
              <li>
                <Link
                  to="/about"
                  style={{ color: "#707070", textDecoration: "none" }}
                >
                  ABOUT&nbsp; US
                </Link>
              </li>
              <li>
                <Link
                  to="/contact"
                  style={{ color: "#707070", textDecoration: "none" }}
                >
                  CONTACT&nbsp;US
                </Link>
              </li>
              <button onClick={() => setShowMenu(false)}>
                <IoClose />
              </button>
            </ul>
          </div>
        </nav>
      </div>
    </>
  );
};

export default HeaderSub;
