import React from 'react';
import '../styles/about.css';
import AboutLanding from '../components/about/about_landing';
import Header from '../components/common/header';
import Footer from '../components/common/footer';
import Whatsapp from "../components/common/Whatsapp";
import HeaderSub from '../components/common/header_sub/HeaderSub.tsx';

const About = () => {
  return (
    <section>
      <Whatsapp/>
        <HeaderSub/>
        <AboutLanding />
        <Footer/>

    </section>
  )
}

export default About