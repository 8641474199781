// car data types
interface Car {
  name: string;
  gearType: string;
  milage: string;
  fuel: string;
  steering: string;
  model: string;
  capacity: string;
  youtubeLink: string;
}

/*car data
input additional car data here
data types follows ##Car##*/

const carData: Car[] = [
  {
    name: "Toyota Rush",
    gearType: "Automatic",
    milage: "N/A",
    fuel: "petrol",
    steering: "power",
    model: "2024",
    capacity: "7 seats",
    youtubeLink: "2lcQNYock8U?si=jEi8MJkwIL6bJBdp",
  },
  {
    name: "Mitsubishi Expander",
    gearType: "Automatic",
    milage: "N/A",
    fuel: "petrol",
    steering: "power",
    model: "2024",
    capacity: "7 seats",
    youtubeLink: "n_Gc74LXc1U?si=Tk89lvjGDKkMq_mE",
  },
  {
    name: "Mercedes Benz S500 2024",
    gearType: "Automatic",
    milage: "N/A",
    fuel: "petrol",
    steering: "power",
    model: "2024",
    capacity: "5 seats",
    youtubeLink: "y9ERo1C9TU0?si=GylCYyzgcVz1pxqd",
  },
  {
    name: "Maserati MC20 2023",
    gearType: "8 Speed Duel Clutch Automatic",
    milage: "N/A",
    fuel: "petrol",
    steering: "power",
    model: "2023",
    capacity: "2 seats",
    youtubeLink: "SYUJKH73AG0?si=FgR7Ri_Qqh_N-GoM",
  },
  {
    name: "Range Rover Vogue P530 2024",
    gearType: "8 Speed Automatic",
    milage: "N/A",
    fuel: "petrol",
    steering: "power",
    model: "2024",
    capacity: "7 Passenger",
    youtubeLink: "S1FCQoZ4tTM?si=VwI4s_IHcJp7AdtW",
  },
  {
    name: "Mercedes G63 Double Night Package 2024",
    gearType: "9 Speed Automatic",
    milage: "N/A",
    fuel: "petrol",
    steering: "power",
    model: "2024",
    capacity: "5 Passenger",
    youtubeLink: "aIXkgeZXjU4?si=VRw6mte_vzXP5k6w",
  },
  {
    name: "Mercedes Benz E Class AMG 2024",
    gearType: "8 Speed Automatic",
    milage: "N/A",
    fuel: "petrol",
    steering: "power",
    model: "2024",
    capacity: "5 Passenger",
    youtubeLink: "vaDuKbiL9NU?si=EhZ5X9bgM4jIWs1o",
  },
  {
    name: "Mercedes Maybach S680 2022",
    gearType: "8 Speed Dual Clutch Automatic",
    milage: "N/A",
    fuel: "petrol",
    steering: "power",
    model: "2022",
    capacity: "4 Passenger",
    youtubeLink: "gsA-uMS52gY?si=Vwos426nyntCaX-T",
  },
  {
    name: "Bentley Flying Spur W12 – Black Edition 2022",
    gearType: "8 Speed Dual Clutch Automatic",
    milage: "N/A",
    fuel: "petrol",
    steering: "power",
    model: "2022",
    capacity: "5 Passenger",
    youtubeLink: "fKVARc6vi6U?si=0JagAdyqBVHiXlaK",
  },
  {
    name: "Rolls Royce Ghost 2022 Black Badge",
    gearType: "8 Speed Dual Clutch Automatic",
    milage: "N/A",
    fuel: "Hybrid",
    steering: "power",
    model: "2022",
    capacity: "4 Passenger",
    youtubeLink: "DTknJQ0WZZ0?si=UTnSUMVZx6g03G3F",
  },
  {
    name: "Mercedes AMG G63 2022 Nardo Grey Double Night Package",
    gearType: "9 Speed Automatic",
    milage: "N/A",
    fuel: "petrol",
    steering: "power",
    model: "2022",
    capacity: "5 Passenger",
    youtubeLink: "kxuvV_ZhsY0?si=KnKo5GtWNUda5IXZ",
  },
  // sports car category.
  {
    name: "Lamborghini Aventador SVJ 2020",
    gearType: "7 Speed Automatic",
    milage: "N/A",
    fuel: "petrol",
    steering: "power",
    model: "2020",
    capacity: "2 Passenger",
    youtubeLink: "A5oTxl-hV0U?si=yitYRK1VGs4Hwerf",
  },
  {
    name: "Chevrolet Corvette Stingray 2023",
    gearType: "8 Speed Duel Clutch Automatic",
    milage: "N/A",
    fuel: "petrol",
    steering: "power",
    model: "2023",
    capacity: "2 Passenger",
    youtubeLink: "7on6Ge2kzg4?si=djMP8DudghlYwrYj",
  },
  {
    name: "Ferrari Portofino M 2023",
    gearType: "8 Speed Duel Clutch Automatic",
    milage: "N/A",
    fuel: "petrol",
    steering: "power",
    model: "2023",
    capacity: "4 Passenger",
    youtubeLink: "13y_VdPpgIM?si=v7GC-YaZGgV1mqqM",
  },
  {
    name: "Ford Mustang 2021",
    gearType: "6 Speed Manual",
    milage: "N/A",
    fuel: "petrol",
    steering: "power",
    model: "2023",
    capacity: "4 Passenger",
    youtubeLink: "X9GF2WZlsIU?si=2ybaS8og0WGlviEM",
  },
  {
    name: "Lamborghini Huracan",
    gearType: "7 Speed Dual Clutch Automatic",
    milage: "N/A",
    fuel: "petrol",
    steering: "power",
    model: "2024",
    capacity: "2 Passenger",
    youtubeLink: "_1r8soGJ0qg?si=5CxRmBe2VOyeeQfo",
  },
  {
    name: "Lamborghini URUS 2022",
    gearType: "8 Speed Automatic with Manual Shifting",
    milage: "N/A",
    fuel: "petrol",
    steering: "power",
    model: "2023",
    capacity: "5 Passenger",
    youtubeLink: "_wxaYP0Nx4k?si=coSfBET6Zep073H0",
  },
  {
    name: "Mercedes Benz AMG GTR",
    gearType: "7-speed dual-clutch automatic with manual shifting mode",
    milage: "N/A",
    fuel: "petrol",
    steering: "power",
    model: "2018",
    capacity: "2 Passenger",
    youtubeLink: "nXzi5H-NO9E?si=VGCF9iFJYZyrKbCH",
  },  
  {
    name: "Porsche 911 Turbo S 2021",
    gearType: "7-speed dual clutch automatic",
    milage: "N/A",
    fuel: "petrol",
    steering: "power",
    model: "2021",
    capacity: "2 Passenger",
    youtubeLink: "NYifTXGwCOM?si=AiZI-DuVSkvOUOrF",
  },  
  {
    name: "Porsche GT3 2024",
    gearType: "7-speed dual clutch automatic",
    milage: "N/A",
    fuel: "petrol",
    steering: "power",
    model: "2024",
    capacity: "2 Passenger",
    youtubeLink: "BKKrcga3y24?si=rlpOM_HB0nQtFX7R",
  },  
  {
    name: "Audi RS5 Sportback 2022",
    gearType: "8-speed automatic transmission",
    milage: "N/A",
    fuel: "petrol",
    steering: "power",
    model: "2022",
    capacity: "4 Passenger",
    youtubeLink: "b7nB8BCZH1c?si=yoEoGofjlGo2iOHe",
  },
]
/* car search function. use this function to search by car name. you can extract all data such as gearType, milage, etc... by searching using car name */
const searchByCarName = (carName: string): Car | undefined => {
  return carData.find(
    (car) => car.name.toLowerCase() === carName.toLowerCase()
  );
};

export { Car, carData, searchByCarName };
